@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: url("lato-v22-latin-ext_latin-regular.7cbce79e.eot");
  src: local(""), url("lato-v22-latin-ext_latin-regular.7cbce79e.eot#iefix") format("embedded-opentype"), url("lato-v22-latin-ext_latin-regular.b03f97ff.woff2") format("woff2"), url("lato-v22-latin-ext_latin-regular.dc49162e.woff") format("woff"), url("lato-v22-latin-ext_latin-regular.7af0de67.ttf") format("truetype"), url("lato-v22-latin-ext_latin-regular.97dd24ba.svg#Lato") format("svg");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: url("lato-v22-latin-ext_latin-700.88c8b097.eot");
  src: local(""), url("lato-v22-latin-ext_latin-700.88c8b097.eot#iefix") format("embedded-opentype"), url("lato-v22-latin-ext_latin-700.423a2e25.woff2") format("woff2"), url("lato-v22-latin-ext_latin-700.b7de945e.woff") format("woff"), url("lato-v22-latin-ext_latin-700.64fe1735.ttf") format("truetype"), url("lato-v22-latin-ext_latin-700.69c872b6.svg#Lato") format("svg");
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 900;
  src: url("lato-v22-latin-ext_latin-900italic.0be6ee30.eot");
  src: local(""), url("lato-v22-latin-ext_latin-900italic.0be6ee30.eot#iefix") format("embedded-opentype"), url("lato-v22-latin-ext_latin-900italic.6d6bec8f.woff2") format("woff2"), url("lato-v22-latin-ext_latin-900italic.a380133c.woff") format("woff"), url("lato-v22-latin-ext_latin-900italic.8a03e5b7.ttf") format("truetype"), url("lato-v22-latin-ext_latin-900italic.d87ec0a8.svg#Lato") format("svg");
}
/*# sourceMappingURL=lato.da6df4b5.css.map */
